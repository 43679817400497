import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { css } from "@emotion/core"
import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { Heading, Text } from "~/components/common/Typography"
import HeroVideo from "~/components/page/HeroVideo"
import { Infobox, InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import Copyright from "~/components/common/Copyright"
import CustomPlayer from "~/components/common/CustomPlayer"

const Page = ({ myPage = 2, mySubpage = 1 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "3.2HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1440, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          video: file(
              name: { eq: "ADIDAS_DigitalCurriculum_Video5_9x16_CTM_190919_3bit" }
          ) {
              name
              publicURL
          }
      }
  `)

  const { hero, video } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: SETTING NON-NEGOTIABLES`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="When girls feel safe, they thrive."
        text={
          <Text mt={0}>
            Making practice a safe space is about more than just physical
            safety. It’s about emotional safety and creating an environment that
            allows girls to bring their true selves to the team.
          </Text>
        }
        image={hero}
        bg={"white"}
      />

      <Infobox uppercase as={"section"}>
        One important part of creating a strong team environment is helping
        girls understand how the program will keep them safe physically,
        emotionally, and psychologically.
      </Infobox>

      <InfoboxDetailed as={"section"} headerMaxWidth={"desktop"}>
        <Flex
          flexDirection={["column", "row"]}
          maxWidth={"desktop"}
          mx={"auto"}
          mt={[3]}
        >
          <Box
            width={["100%", "50%"]}
            textAlign={["center", "left"]}
            pl={[0, 7]}
            pr={[0, 6]}
            fontSize={["16px", "14px", "18px"]}
            lineHeight={["24px", "28px", "36px"]}
          >
            <Text mt={[5, 0]}>
              Let’s talk rules, or what we call our strict behavior code (SBC),
              and how they can allow for safe play and expression that will have
              a significant impact on the lives and development of your
              athletes.
            </Text>
            <Text>
              Growing up, girls will be expected to follow different sets of
              rules depending on where they are. For example, they’ll have
              different rules for school compared to home compared to a social
              event. What’s more, girls tend not to have much say in how rules
              are developed. A strict behavior code helps to address the
              challenges of sports before they arise and gives girls an inherent
              understanding of how things are done in your program.
            </Text>
            <Text>
              Safety is an important feeling for girls to feel every time they
              enter your program. A strict behavior code helps to accomplish
              this by not only stating the most important behaviors, but also
              giving girls the opportunity to have a say in its creation and
              helps them feel in control of the program and their participation.
            </Text>
          </Box>
          <Box width={["100%", "50%"]}>
            <Box width={"100%"} maxWidth={["253px", "337px"]} mx={"auto"}>
              <CustomPlayer url={video.publicURL} videoLabel={video.name} vertical></CustomPlayer>
            </Box>
          </Box>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        as={"section"}
        bg={"seafoam"}
        headerText={"Let’s break it down"}
        headerBackground={"transparent"}
        contentMaxWidth={"desktop"}
      >
        <Box bg={"white-panel"} mt={[5, 6]} px={[5, 5, 6]} py={[3, 6]}>
          <Box maxWidth={"mediumContent"} mx={"auto"}>
            <Heading
              fontFamily={"PermanentMarkerRegular"}
              fontSize={["32px", "48px"]}
              lineHeight={["47px", "68px"]}
            >
              Strict
            </Heading>
            <Text mb={7}>
              Strict doesn’t mean punitive or militaristic. Strict refers to a
              set of rules that everyone takes seriously and closely abides by.
              You as a coach should identify a few key behaviors that are the
              most important non-negotiables for the team. These are things that
              are never OK in your program, regardless of when or why they might
              be happening. Think: no physical violence, teasing, or anything
              that isolates or humiliates players. It’s not about making a big
              set of rules, it’s about making a handful that go beyond sports to
              address how players treat each other.
            </Text>
            <Heading
              fontFamily={"PermanentMarkerRegular"}
              fontSize={["32px", "48px"]}
              lineHeight={["47px", "68px"]}
            >
              Behavior
            </Heading>
            <Text mb={7}>
              Words can be subjective. Certain words can mean something
              different to each player. That’s why it’s important for you as a
              coach to identify and verbalize the behaviors you want to see and
              hear in the program and avoid terms without a clearly defined
              meaning. For example,{" "}
              <span
                css={css`
                  font-family: "AdineuePRORegularItalicWeb";
                `}
              >
                being respectful
              </span>{" "}
              is a common expectation for players to hear. But{" "}
              <span
                css={css`
                  font-family: "AdineuePRORegularItalicWeb";
                `}
              >
                respect
              </span>{" "}
              is a word that can have many literal meanings. To some, it may
              mean showing up early to a practice or game. To others, it may
              mean formally greeting others whenever they arrive at practice or
              a game. Instead of saying, “Be respectful,” you should say,
              “Remain silent when someone else is talking.” This focuses on the
              behaviors you want to see that demonstrate respect.
            </Text>
            <Heading
              fontFamily={"PermanentMarkerRegular"}
              fontSize={["32px", "48px"]}
              lineHeight={["47px", "68px"]}
            >
              Code
            </Heading>
            <Text mb={7}>
              The word{" "}
              <span
                css={css`
                  font-family: "AdineuePRORegularItalicWeb";
                `}
              >
                rule
              </span>{" "}
              sometimes has a negative connotation. For many young athletes, it
              is interpreted as something they have to do even if they don’t
              agree with it or don’t know why they have to do it. The word{" "}
              <span
                css={css`
                  font-family: "AdineuePRORegularItalicWeb";
                `}
              >
                code
              </span>{" "}
              is more collaborative. Consider things in your strict behavior
              code that players can agree are important and necessary.
            </Text>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "36px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              Remember: safety is the root of a positive sporting experience. A
              safe environment will help girls feel free to take safe risks,
              learn new things, and grow as athletes and women.
            </Text>
          </Box>
        </Box>
      </InfoboxDetailed>

      <InfoboxDetailed
        as={"section"}
        headerText={"Code in action"}
        contentMaxWidth={"desktop"}
        pb={4}
      >
        <Box maxWidth={"mediumContent"} mx={"auto"}>
          <Text>
            It’s important to reinforce your code each time your team meets. You
            could do this by:
          </Text>
          <Box my={6}>
            <Heading
              fontFamily={"PermanentMarkerRegular"}
              fontSize={["20px", "24px"]}
              lineHeight={["30px", "27px"]}
            >
              1) Posting your SBC on your field of play
            </Heading>
          </Box>

          <Box my={6}>
            <Heading
              fontFamily={"PermanentMarkerRegular"}
              fontSize={["20px", "24px"]}
              lineHeight={["30px", "27px"]}
            >
              2) Having your starting huddle take place next to your SBC
            </Heading>
          </Box>
          <Box my={6}>
            <Heading
              fontFamily={"PermanentMarkerRegular"}
              fontSize={["20px", "24px"]}
              lineHeight={["30px", "27px"]}
            >
              3) Reciting the SBC as a part of your introduction to the day
            </Heading>
          </Box>

          <Text
            uppercase
            fontSize={["18px", "24px"]}
            lineHeight={["27px", "36px"]}
            fontFamily={"AdineuePROBoldWeb"}
          >
            Take some time to think about what you would put in your SBC and how
            you might reinforce it with your players.
          </Text>
        </Box>
      </InfoboxDetailed>
      <Copyright/>
    </Layout>
  )
}

export default withProgress(Page, 2, 1)
